import React, { Component } from 'react';
import MapTable from './MapTableGrid';
import TradePane from './TradePane';
import { Col, Container, Row, Form,Card } from 'react-bootstrap';
import { CURRENT_HARDCORE_LEAGUE, CURRENT_LEAGUE, EVENT_LEAGUE, HARDCORE_EVENT_LEAGUE } from './Leagues';
import { Announcement } from './Announcement';

//const socket = io(`http://localhost:10000`)

export default class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      mapjson: { standard: {lines:[]}, league: {lines:[]}, invalid: {lines: []} },
      selectedMaps: Array(17).fill([]),
      character: "",
      league: "",
      leagueSelector: "league",
      lastChararacter : "",
      showMapText : true,
      ignoreWatchstoneMaps : false

    };

    this.handleChange = this.handleLeagueChange.bind(this);
    this.handleSubmit = this.handleCharacterChange.bind(this);
    this.handleCharacterSelectChange = this.handleCharacterSelectChange.bind(this);
    this.showMapTextClick = this.showMapTextClick.bind(this);
    this.ignoreWatchstoneMapsClick = this.ignoreWatchstoneMapsClick.bind(this);
  }

  componentDidMount() {

    //get standard league map json data
    fetch('standard.json')
      .then( res => res.json() )
      .then(
        (json) => {
          this.setState((prevState) => ({
            isLoaded: true,
            mapjson: {
              ...prevState.mapjson,
              standard: json}
          }));
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );

      fetch('league.json')
      .then( res => res.json() )
      .then(
        (json) => {
          this.setState((prevState) => ({
            isLoaded: true,
            mapjson: {
              ...prevState.mapjson,
              league: json}
          }));
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );

      //local storage checks

      if(localStorage.getItem("showMapText") === "false"){
        this.setState({showMapText:false}) 
      }

      if(localStorage.getItem("ignoreWatchstoneMaps") === "true"){
        this.setState({ignoreWatchstoneMaps:true})
      }
      
  }


  //load the last character but we need to wait for the promise from the login data fetch
  //in MainRouter to settle so wait untill we can see a change in "logged_in" from the prop
  componentDidUpdate(prevProps, prevState) {

    if (prevProps.loginData.logged_in !== this.props.loginData.logged_in) {
  

      if(this.props.loginData.logged_in){
        let lastchar = localStorage.getItem("lastchar");

        let f = this.props.loginData['characters'].find( (x)=> x['name'] === lastchar);

        if(f !== undefined){
   
          let e = { target : {value: lastchar}};
          this.handleCharacterSelectChange(e);
        }
      }
  
    }
  
  }


  showMapTextClick = () =>{

    this.setState( prev => {
     localStorage.setItem("showMapText", !prev.showMapText)
     return {showMapText: !prev.showMapText}
    }) 

  }

  ignoreWatchstoneMapsClick = () =>{

    this.setState( prev => {
     localStorage.setItem("ignoreWatchstoneMaps", !prev.ignoreWatchstoneMaps)
     return {ignoreWatchstoneMaps: !prev.ignoreWatchstoneMaps}

    }) 

  }

  updateSelectedMaps = (tier,want,name) => {


    this.setState( prevState => {
      let m = prevState.selectedMaps.slice();
      let result = [];

      //return {selectedMaps: m}

      if(m[tier].some( x => x.want === want && x.name === name )){


        result = m[tier].filter( x => !(x.want === want && x.name === name) );
      }
      else{
        
        result = [...m[tier], {want: want, name: name}];
      }



      m[tier] =  result;

      let taco = [];
      taco[tier] = result;



      return {selectedMaps: m }

    });
    

  }


  createMapTables = () =>{
    const twidth = 5;

      //valid options are
      //invalid,standard and league


      if(this.state.leagueSelector !== "invalid"){
        let maptables = [];



        const mapjson = [...this.state.mapjson[this.state.leagueSelector]["lines"]]

        // Ignore watchstone filter
        //
        // If ignore watchstone is set then go through the list of map lines
        // and only keep the lowest maps


        let mdata = {}

        if(this.state.leagueSelector === "league" && this.state.ignoreWatchstoneMaps){

          let ignoreList = [];
          let maplines = [];

          mapjson.forEach((x,i) =>{


              if(!ignoreList.includes(x.name)){

                let currentMap = {...x};

                mapjson.forEach((y,i) =>{

                    if(currentMap.name === y.name && y.mapTier < currentMap.mapTier){ 
                      currentMap = {...y};
                      
                    }

                });

                ignoreList.push(currentMap.name)
                maplines.push(currentMap)

              }

          });

          mdata = { lines : maplines}

        } else{
           mdata = this.state.mapjson[this.state.leagueSelector]
        }


        for (let i = 1; i <= 16; i++){
          maptables.push(<MapTable 
            tier={i} 
            mapdata={mdata} 
            width={twidth} 
            updateSelectedMaps={this.updateSelectedMaps} 
            selectedMaps={this.state.selectedMaps[i]} 
            showMapText={this.state.showMapText}
            />);
        }

        return maptables;
      }
      else{
        return(
          <Row className="justify-content-center align-items-center my-6">
            <Col className="col-auto">
            <Card bg='danger' text='white' border='danger' style={{ width: '50rem' }}>
              <Card.Body>
              <Card.Title>Invalid League</Card.Title>
              <Card.Text>The character you selected is either in a league that does not have Trading
                or is not supported by PoeMap.Trade
              </Card.Text>
              </Card.Body>
            </Card>
            </Col>
          </Row>
        )
        

      }
  }

  createCharacterSelect = () =>{

    let options = []

    this.props.loginData['characters'].forEach( (character) =>{
      options.push(<option 
        value={ character['name'] }>
          {character['name'] + " - " + character['league'] + " League"  }
        </option> )

    })

 

    return(
      <Row className='justify-content-center my-3'>
        <Col className="col-auto bg-secondary border border-primary rounded">
        <Form>
          <Form.Group className="mb-3" controlId="formCharacter">
              <Form.Label>Please select your character:</Form.Label>
              <Form.Select value={this.state.character} onChange={this.handleCharacterSelectChange} aria-label="character select">
              <option value="">Character - League</option>
              {options}
              </Form.Select>
          </Form.Group>
        </Form>
        </Col>
      </Row>
    )

  }

  handleCharacterSelectChange = (event) =>{
    let char = event.target.value;
    let league = ""
    if(char !== ""){
        let r = this.props.loginData['characters'].find( (x)=> x['name'] === char);
        league = r['league'].toLowerCase();
    }

    let leagueselect = "";
    switch (league){
      case "hardcore":
      case "standard":
        leagueselect = "standard";
        break;
      case "":
      case EVENT_LEAGUE:
      case HARDCORE_EVENT_LEAGUE:
      case CURRENT_LEAGUE:
      case CURRENT_HARDCORE_LEAGUE:
        leagueselect = "league";
        break;
      default:
        leagueselect = "invalid"
    }

    localStorage.setItem("lastchar",char)

    this.setState({character:char,
      league: league,
      leagueSelector: leagueselect,
      selectedMaps: Array(17).fill([])
    })

  }
 
  handleCharacterChange = (event) =>{
    this.setState({character: event.target.value});
  }

  handleLeagueChange = (event) =>{
    this.setState({league: event.target.value});
  }

  render() {
    const { error, isLoaded, mapjson, selectedMaps, character,league } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {

      //console.log(items);

      let devform;

      if(process.env.NODE_ENV !== 'production'){

        devform =(<Row className="justify-content-center"><Col className="col-sm-auto"><Form>
          <Form.Group className="mb-3" controlId="formCharacter">
            <Form.Label>Character:</Form.Label>
            <Form.Control type="character" placeholder="Character" onChange={this.handleCharacterChange} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formLeague">
            <Form.Label>League</Form.Label>
            <Form.Control type="league" placeholder="League" onChange={this.handleLeagueChange} />
          </Form.Group>
        </Form></Col></Row>);
      }
      else{
        devform = null;
      }


      let loginNotification;
      let charSelect;

      if(this.props.loginData['logged_in']){
        loginNotification = null;
        charSelect = this.createCharacterSelect();
      }
      else{
        charSelect = null;
        loginNotification = <Row className="justify-content-center align-items-center my-3">
          <Col className="col-auto">
          <Card bg='secondary' text='white' border='primary' style={{ width: '40rem' }}>
        <Card.Body>
          <Card.Title>
            Please <a href="https://poemap.trade/srv/login">Login</a> to your Path of Exile Account to start trading
          </Card.Title>
        </Card.Body>
      </Card>
      </Col>
      </Row>
      }

      let maptables = this.createMapTables();

      return (
        <div className="Maincontainer">
        <Container fluid>
        {Announcement}
        {loginNotification}
        <Row className="justify-content-center pt-5 pb-3">
        <Col className="border-bottom border-secondary" md={3}><h5>Want</h5></Col>
        <Col md={1}></Col>
        <Col className="border-bottom border-secondary" md={3}><h5>Have</h5></Col> 
      </Row>
      {maptables}
      {charSelect}
      {devform}
      <TradePane selectedMaps={selectedMaps} 
      mapdata={mapjson[this.state.leagueSelector]} 
      character={character} 
      league={league}
      showMapTextClick={this.showMapTextClick}
      showMapText={this.state.showMapText}
      ignoreWatchstoneMapsClick={this.ignoreWatchstoneMapsClick}
      ignoreWatchstoneMaps={this.state.ignoreWatchstoneMaps}
      />
        </Container>
        </div>
      );
    }
  }
}

