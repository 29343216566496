import React from 'react';
import { Col,  Row, Card } from 'react-bootstrap';

export let Announcement = <Row className="justify-content-center align-items-center my-3">
          <Col className="col-auto">
            <Card bg='secondary' text='white' border='primary' style={{ width: '40rem' }}>
              <Card.Body>
                <Card.Title>
                  Support for Legacy of Phrecia has been added<br></br>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>


/* export let Announcement = null */
